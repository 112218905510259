import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { Box } from '@latitude/box';
import { Heading4 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { ALIGN, COLOR, BREAKPOINT } from '@latitude/core/utils/constants';
import { Hero } from '@latitude/hero';
import { FeaturesSlider } from '@latitude/features-slider';
import { AccordionSidebar } from '@latitude/accordion';
import RatesAndFees from '@latitude/rates-and-fees';
import { StickyNavigation } from '@latitude/sticky-navigation';
import Layout from '../../components/layout';
import PageData from '../../data/pages/gem-creditline-card.json';
import withStickyState from '../../hoc/withStickyState';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import Lframe from '../../components/Lframe/Lframe';
import './gem-visa-card.scss';
import { featuresSliderIE11Fix } from '../../utils/stylesUtils';
import PaymentOptions from './_payment-options-creditline';
import cardImage from '../../images/credit-cards/creditline.png';
import importantInformationData from '../../data/json/disclaimer/gem-creditline.json';
import CalloutHelp from './_callout-help';
import faqsCredit from './_faqs';
import { PageContext } from '../../context/PageContext';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';

const GemCreditlinePage = props => {
  const featureSliderItems = [
    {
      icon: 'icon-easy',
      title: 'Get what you want. Now',
      text:
        ' Over 2000 retailers all around New Zealand offer long term interest free deals, so you can buy now and pay it off when you&rsquo;re ready.<sup>3</sup>'
    },
    {
      icon: 'icon-party-popper',
      title: 'Shop now, pay later',
      text:
        'Spend over $100 at one of our participating stores, and you don&rsquo;t need to pay a cent for up to 120&nbsp;days &ndash; yay!<sup>2</sup>'
    },
    {
      icon: 'icon-credit-card',
      title: 'Swipe and go',
      text:
        'Use your Gem CreditLine card like an EFTPOS card &ndash; swipe it in store or take cash out from a money machine<sup>1</sup>. Super convenient.'
    },
    {
      icon: 'icon-unlimited-rewards-branded',
      title: 'Looking for a change?',
      text:
        'Want more great features? With Gem Visa, you&rsquo;ll get the added convenience of an everyday Visa card, as well as all those interest free deals you know and love built&nbsp;in. <a href="/credit-cards/gem-visa-card/">Find out more</a>.'
    }
  ]
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [{
    heading: "Have a Gem CreditLine?<br />Don&rsquo;t forget these great features",
    featureCards: featureSliderItems
  }];
  /** - END - */

  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer gem-visa-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/loans/gem-creditline-card/"
            />
            <title>Gem CreditLine Card | Gem by Latitude</title>
            <meta
              name="description"
              content="Gem Visa is perfect for everyday spending. We no longer offer Gem CreditLine to new applicants. T&amp;Cs &amp; fees apply."
            />
          </Helmet>

          <Lframe cardLowRate />

          <Hero
            imageContent={
              <div
                id="hero"
                css={`
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    justify-content: flex-end;
                    margin-bottom: 80px;
                  }
                `}
              >
                <img
                  src={cardImage}
                  css={`
                    height: 150px;
                    width: auto;

                    @media (min-width: ${BREAKPOINT.LG}) {
                      height: 220px;
                    }
                  `}
                  alt="Gem CreditLine Card"
                />
              </div>
            }
            title={['Gem CreditLine']}
            text={[
              'Because <a href="/credit-cards/gem-visa-card/">Gem Visa</a> is perfect for great deals and everyday spending, we don&rsquo;t offer Gem CreditLine anymore. But don&rsquo;t worry, if you don&rsquo;t want to switch, your Gem CreditLine card will keep working.'
            ]}
          />

          <div className="d-none d-lg-block">
            <StickyNavigation
              items={PageData.nav}
              isSticky={props.isNavSticky}
              onStickyNavStateChange={props.handleStickyNavStateChange}
              phoneNumber={PageData.content.phoneNumber}
              offsetElem="[data-sticky-navigation-offset]"
              {...state?.inPageNavData?.[0]}
            />
          </div>

          {featureSliderData[0] && (
            <FeaturesSlider
              id="why-us"
              data={featureSliderData[0].featureCards}
              className="pb-0"
              css={
                featuresSliderIE11Fix +
                `
                h5 {
                  @media (max-width: ${BREAKPOINT.XS}) {
                    height: 48px
                  }
                  @media (min-width: ${BREAKPOINT.SM}) {
                    height: 64px
                  }
                }`
              }
              heading={featureSliderData[0].heading}
              subheading={featureSliderData[0].description}
              responsiveSettings={PageData.whyChooseLatitudeResponsoveSettings}
            />
          )}
          {state?.ratesAndFeesData?.[0] ? (
            <LabRatesAndFees {...state.ratesAndFeesData[0]} />
          ) : (
            <RatesAndFees
              id="rates"
              title="Rates, Fees & Conditions Of Use"
              className="rates-list-section"
              ratesListProps={{ data: PageData.ratesList }}
              accordionProps={{
                items: [
                  {
                    id: 'account-fees',
                    title: 'Account fees',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Annual account fee</strong>
                          </p>
                          <p className="w-100">
                            $65 (charged $32.50 half-yearly)
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Paper statement reprint fee</strong>
                          </p>
                          <p className="w-100">$5</p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Card replacement</strong>
                          </p>
                          <p className="w-100">$5</p>
                        </div>
                      </div>
                    )
                  },
                  {
                    id: 'transaction-fees',
                    title: 'Transaction fees',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Late fee</strong>
                          </p>
                          <p className="w-100">$15</p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Cash advance fee</strong>
                          </p>
                          <p className="w-100">
                            $6 for over the counter cash withdrawals
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>ATM withdrawal fee</strong>
                          </p>
                          <p className="w-100">90&#162;</p>
                        </div>
                      </div>
                    )
                  },
                  {
                    id: 'conditions',
                    title: 'Gem CreditLine Conditions of Use',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            These are the Conditions of Use, <br />
                            which govern your Gem CreditLine credit card.
                          </p>
                          <p className="w-100">
                            <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-nz/cou.pdf">
                              <img
                                alt="Gem CreditLine Conditions of Use"
                                width="100px"
                                src="/media/creditline-cou.png"
                              />
                            </a>
                          </p>
                        </div>
                      </div>
                    )
                  }
                ]
              }}
            />
          )}

          <PaymentOptions />

          <Box id="faq">
            <Box.Section>
              <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
                Frequently asked questions
              </Heading4>
              <AccordionSidebar data={faqsCredit.gemCreditLine} />
            </Box.Section>
          </Box>

          <CalloutHelp />

          <ImportantInformation
            id="important-information"
            data={importantInformationData}
            sectionOneColummClasses="col-10 offset-1 col-lg-10 offset-lg-1"
            {...state?.importantInfoData?.[0]}
          />
        </Box>
      </main>
    </Layout>
  );
}

const GemCreditlinePageWithSticky = withStickyState(GemCreditlinePage);

export default GemCreditlinePageWithSticky;
